import { useState, useEffect } from "react";
import { Donor, getAllDonorsNames, getDonorsPage } from "./../cms/utilities";
import Header from "../components/header";
import PageSection from "../components/pageContent";
import heroBg from "../images/donate/donate_hero_bg.jpg";
import rightArrow from "../images/icons/right-arrow.svg";
import wrapupBg from "../images/donors/donors_wrapup-bg.jpg";
import mobileWrapup from "../images/donors/donors_wrapup-mobile.jpg";
import halfwayMessageBg from "../images/donors/donors-halfway-message.jpg";

//@ts-ignore
import Fade from "react-reveal/Fade";
import { alphabet } from "../cms/constants";
import { Link } from "react-router-dom";

const Donors = () => {
	let isDesktop = window.innerWidth >= 1024;

	const [donors, setDonors] = useState<Donor[]>([]);
	const [numTopHalfLetters, setNumTopHalfLetters] = useState<number>(0);

	const DEBUG_NAMES = false;  // Toggle debug logging

	// Helper function for debug logging
	const debugLog = (message: string, ...args: any[]) => {
		if (DEBUG_NAMES) {
			if (args.length > 0) {
				console.log(`[DEBUG] ${message}`, ...args);
			} else {
				console.log(`[DEBUG] ${message}`);
			}
		}
	};

	useEffect(() => {
		const fetchDonors = async () => {
			const donorsPage = await getDonorsPage();
			let fullNames = donorsPage.acf.donors_list.split(",");
			let donorsNames: Donor[] = [];

			// Helper function to clean graduation years and get proper sort name
			const getSortName = (name: string): string => {
				debugLog('\n========== Processing New Name ==========');
				debugLog(`Input name: "${name}"`);
				
				// Clean graduation years first
				const cleanName = name.replace(/'\d{2,4}/g, '').trim();
				debugLog(`After cleaning graduation years: "${cleanName}"`);
				
				// Handle foundation names first
				if (cleanName.includes(' Foundation') || cleanName.includes(' Family Foundation')) {
					debugLog(`Processing foundation name: "${cleanName}"`);
					
					// Extract the main name before "Foundation" and split into words
					const beforeFoundation = cleanName.split(/(?: Family)? Foundation/)[0];
					debugLog(`Name before 'Foundation': "${beforeFoundation}"`);
					
					const foundationParts = beforeFoundation.split(' ');
					debugLog(`Split into parts:`, foundationParts);
					
					// Return the last meaningful word (usually the family name)
					for (let i = foundationParts.length - 1; i >= 0; i--) {
						debugLog(`Checking part: "${foundationParts[i]}"`);
						// Skip common prefixes, titles, and connectors
						if (!['&', 'and', 'M.', 'The', 'Family', 'David'].includes(foundationParts[i])) {
							debugLog(`Found valid name part: "${foundationParts[i]}"`);
							return foundationParts[i];
						} else {
							debugLog(`Skipping common word: "${foundationParts[i]}"`);
						}
					}
					debugLog(`No valid name found, using first part: "${foundationParts[0]}"`);
					return foundationParts[0];
				}
				
				// Handle special titles like "The Rev."
				if (cleanName.toLowerCase().includes('rev.')) {
					debugLog(`Processing reverend name: "${cleanName}"`);
					const nameParts = cleanName.replace(/^The /, '').split(' ');
					debugLog(`Split into parts:`, nameParts);
					
					// Find the last word that's not a graduation year
					for (let i = nameParts.length - 1; i >= 0; i--) {
						debugLog(`Checking part: "${nameParts[i]}"`);
						if (!nameParts[i].includes("'") && !['Rev.'].includes(nameParts[i])) {
							debugLog(`Found valid name part: "${nameParts[i]}"`);
							return nameParts[i];
						} else {
							debugLog(`Skipping special word: "${nameParts[i]}"`);
						}
					}
				}
				
				// Handle other organizations
				const isOrg = !cleanName.includes(',') && 
					(cleanName.includes(' Inc.') || 
					cleanName.includes(' LLC') || 
					cleanName.includes('Digital Media') ||
					cleanName.includes('Creative Organization') ||
					cleanName.includes('Teachers') ||
					cleanName.includes('Express'));
					
				debugLog(`Is organization? ${isOrg}`);
				
				if (isOrg) {
					// Remove 'The ' prefix if present
					const orgName = cleanName.startsWith('The ') ? cleanName.substring(4) : cleanName;
					debugLog(`Returning org name: "${orgName}"`);
					return orgName;
				}
				
				// Handle names with parentheses
				if (cleanName.includes('(')) {
					// Split by "and" or "&" first to handle cases with spouses
					const spouseParts = cleanName.split(/ and | & /);
					const relevantPart = spouseParts[spouseParts.length - 1].trim();
					// Get the last word that's not in parentheses
					const words = relevantPart.split(' ');
					for (let i = words.length - 1; i >= 0; i--) {
						if (!words[i].includes('(') && !words[i].includes(')')) {
							return words[i];
						}
					}
				}
				
				// For all other cases (couples, single names)
				// Split by "and" or "&" to handle couples
				const parts = cleanName.split(/ and | & /);
				const lastPart = parts[parts.length - 1].trim();
				// Get the last word that's not empty
				const words = lastPart.split(' ').filter(word => word.length > 0);
				return words[words.length - 1];
			};

			fullNames.map((name: string) => {
				const sortName = getSortName(name);
				debugLog(`Processing name: "${name.trim()}" -> Sort name: "${sortName}"`);
				
				donorsNames.push({
					firstName: "", // We'll keep these empty for now as they're not used
					lastName: sortName,
					fullName: name.trim(),
				});
			});

			// Sort the donors array by the cleaned sort name
			donorsNames.sort((a, b) => a.lastName.localeCompare(b.lastName));

			let numLetters = 0;
			alphabet.map((letter: string, key: any) => {
				let letterNames = donorsNames.filter((donor: Donor) => {
					return donor.lastName.charAt(0).toUpperCase() === letter;
				});

				if (letterNames.length > 0) {
					numLetters++;
				}
			});
			let topHalfLetters = Math.ceil(numLetters / 2);

			if (donorsNames) {
				setDonors(donorsNames);
				setNumTopHalfLetters(topHalfLetters);
			}
		};
		fetchDonors();
	}, []);

	const generateLettersMarkup = () => {
		let lettersDone = 0;
		let lettersMarkup = <></>;

		alphabet.map((letter: string, index: any) => {
			let letterNames = donors.filter((donor: Donor) => {
				return donor.lastName.charAt(0).toUpperCase() === letter;
			});

			if (letterNames.length > 0) {
				letterNames.sort(function (a, b) {
					var textA = a.lastName.toUpperCase();
					var textB = b.lastName.toUpperCase();
					return textA < textB ? -1 : textA > textB ? 1 : 0;
				});

				if (lettersDone === numTopHalfLetters) {
					lettersMarkup = (
						<>
							{lettersMarkup}
							<div className="halfway_message">
								<div
									className="message-bg"
									style={{ backgroundImage: `url(${halfwayMessageBg})` }}
								>
									<Fade bottom>
										<h1 className="message">Every Knight. Every Day.</h1>
									</Fade>
								</div>
							</div>
							<div className="letter-section" key={`${index}`}>
								<Fade bottom>
									<h1 className="letter-heading">{letter}</h1>
									<div className="name-list">
										{letterNames.map((donor, indexx) => {
											return (
												<p className="donor-name" key={`${indexx}`}>
													{`${donor.fullName}`}
												</p>
											);
										})}
									</div>
								</Fade>
							</div>
						</>
					);
				} else {
					lettersMarkup = (
						<>
							{lettersMarkup}
							<div className="letter-section" key={`${index}`}>
								<Fade bottom>
									<h1 className="letter-heading">{letter}</h1>
									<div className="name-list">
										{letterNames.map((donor, indexx) => {
											return (
												<p className="donor-name" key={`${indexx}`}>
													{`${donor.fullName}`}
												</p>
											);
										})}
									</div>
								</Fade>
							</div>
						</>
					);
				}

				lettersDone++;
			}
		});

		return lettersMarkup;
	};

	return (
		<div className="container donors">
			{!isDesktop && <Header logoColor="blue" />}
			<PageSection className="donors-section_intro">
				<div
					className="hero-bg"
					style={isDesktop ? { backgroundImage: `url(${heroBg})` } : {}}
				>
					{isDesktop && <Header logoColor="blue" />}
					<Fade bottom>
						<h1 className="heading">
							You Make Us <b>Better.</b>
						</h1>
						<p className="intro-text">
							{/* The donors listed below have made the Hoban experience better for all students,
							now and in the future. Their gifts will ensure Hoban can continue to provide a
							Holy Cross education to the next generation of leaders—regardless of their
							financial circumstances—with the competence to see and the courage to act.{" "}
							<strong>
								Are you ready to join them in leading Hoban confidently into the future?
							</strong> */}
							The following donors have answered the call and graciously contributed to Every Knight, Every Day. Their belief in the campaign pillars of Achievement, Community & Wellbeing and Access will help ensure the vibrancy of Hoban for generations to come.&nbsp;
							<strong>
								Are you ready to join them in leading Hoban confidently into the future?
							</strong>
						</p>
					</Fade>
				</div>
			</PageSection>
			<PageSection className="donors-section_names">
				{isDesktop && <p className="vertical-title">Donors</p>}
				{donors && generateLettersMarkup()}
			</PageSection>
			<PageSection className="donors-section_wrapup">
				<div
					className="wrapup-bg"
					style={
						isDesktop
							? { backgroundImage: `url(${wrapupBg})` }
							: { backgroundImage: `url(${mobileWrapup})` }
					}
				>
					<Fade bottom>
						<p className="title">True to you</p>
						<p>Join us in strengthening the Hoban experience.</p>
						<Fade bottom>
							<Link to="/donate" className="cta-button centered margin-bottom">
								<span>Donate Now</span>
								<img src={rightArrow} />
							</Link>
						</Fade>
					</Fade>
				</div>
			</PageSection>
		</div>
	);
};

export default Donors;
